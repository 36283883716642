.clear-filter-button {
    display: flex;
    align-items: center;
    background-color: #f8d7da;
    border: 1px solid #dc3545;
    border-radius: 4px;
    padding: 6px 12px;
    margin: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.clear-filter-button:hover {
    background-color: #dc3545;
    color: #fff;
}

.clear-filter-icon {
    margin-right: 8px;
}
