.footer {
    background-color: #373665;
    color: #fff;
    padding: 20px;
}

.footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-info {
    text-align: center;
}

.footer-info a, .footer-info p {
    margin: 5px 0;
    text-decoration: none;
    color: #ffffff;
}

.card {
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 25px;
    gap: 20px;
}

.socialContainer {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: rgb(44, 44, 44);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition-duration: .3s;
}

.containerOne:hover {
    background-color: #d62976;
    transition-duration: .3s;
}

.containerTwo:hover {
    background-color: #00acee;
    transition-duration: .3s;
}

.containerThree:hover {
    background-color: #0072b1;
    transition-duration: .3s;
}

.containerFour:hover {
    background-color: #128C7E;
    transition-duration: .3s;
}

.socialContainer:active {
    transform: scale(0.9);
    transition-duration: .3s;
}

.socialSvg {
    width: 17px;
}

.socialSvg path {
    fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
    animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}