.filter-button {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border: 1px solid #373665;
    border-radius: 4px;
    padding: 6px 12px;
    margin: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.filter-button.selected {
    background-color: #373665;
    color: #fff;
}

.filter-button:hover {
    background-color: #373665;
    color: #fff;
}

.filter-icon {
    margin-right: 8px;
}
