.lettering{
    display: flex;
    width: 100vw;
    min-height: 10px;
    background: #373665;
    color: #ffffff;
    font-size: large;
    font-weight: bold;
    align-items: center;
    justify-content: center;
}