.swiper-oldin-photos {
    width: 960px;
    height: 530px;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
}

.swiper-oldin-photos .mySwiper {
    height: 15%;
    max-width: 100%;
    margin-top: 20px;
}

.swiper-oldin-photos .mySwiper2 {
    height: 75%;
    max-width: 100%;
}


.swiper-oldin-photos .swiper-button-prev,
.swiper-oldin-photos .swiper-button-next {
    width: 30px;
    height: 30px;
    background-color: transparent;
    color: #ffffff;
    opacity: 0.5;
    font-size: 16px;
}


.swiper-oldin-photos .swiper-button-prev:hover,
.swiper-oldin-photos .swiper-button-next:hover {
    opacity: 5.5;
}

.swiper-oldin-photos .mySwiper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-oldin-photos .mySwiper2 img {
    width: 960px;
    height: 720px;
    object-fit: cover;
}