.login-register-section {
    display: flex;
    align-items: center;
}

.login-button,
.register-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #d1d5da;
    color: #24292e;
    cursor: pointer;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 5px;
}

.login-button span,
.register-button span {
    margin-left: 5px;
}

.register-button {
    margin-left: 10px;
    background-color: #373665;
    color: #ffffff;
}

.user-login-container {
    position: relative;
}

.user-profile {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-avatar {
    margin-right: 5px;
}

.dropdown-icon {
    margin-left: 5px;
}

.user-profile.active .user-info {
    color: #007BDA;
}

.user-dropdown-menu {
    list-style-type: none;
    background-color: #f9f9f9;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 0;
    margin: 0;
    display: none;
    z-index: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 200px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.user-dropdown-menu li {
    font-size: 16px;
    color: #24292e;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-profile:hover .user-dropdown-menu {
    display: block;
    border: 1px solid #ccc;
    transition-delay: 0.1s;
}

.user-dropdown-menu li {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.user-dropdown-menu li:hover {
    background-color: #f1f1f1;
    color: #007BDA;
}

.user-dropdown-menu li {
    font-size: 16px;
    color: #24292e;
    padding: 12px 16px;
    display: flex;
    align-items: center;
}

.user-dropdown-menu li .user-icon,
.user-dropdown-menu li .cart-icon,
.user-dropdown-menu li .signout-icon {
    margin-right: 8px;
}

.user-profile:hover .user-dropdown-menu {
    display: block;
    border: 1px solid #ccc;
    transition-delay: 0.1s;
}

.disabled {
    visibility: hidden;
    pointer-events: none;
}