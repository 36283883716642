.card-product {
    width: 250px;
    height: 300px;
    padding: .8em;
    background: #f5f5f5;
    position: relative;
    overflow: visible;
    border: 2px solid #373665;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: 15px;
}

.card-product:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
}

.promotion-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ffbb00;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.card-img-product {
    background-color: #ffcaa6;
    height: 40%;
    width: 100%;
    border-radius: .5rem;
    transition: .3s ease;
    overflow: hidden;
}

.card-img-product img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-info-product {
    padding-top: 10%;
}

.svg-icon-product {
    width: 20px;
    height: 20px;
}

.card-footer-product {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}

.text-last-price-product {
    color: #888;
    font-size: 0.9em;
    text-align: left;
    text-decoration: line-through;
}

.text-title-product {
    font-weight: 900;
    font-size: 1.2em;
    line-height: 1.5;
    text-align: left;
}



.text-body-product {
    font-size: .9em;
    padding-bottom: 10px;
    word-wrap: break-word;
}

.card-button-product {
    border: 1px solid #252525;
    display: flex;
    padding: .3em;
    cursor: pointer;
    border-radius: 50px;
    transition: .3s ease-in-out;
}

.card-img-product:hover {
    box-shadow: rgba(226, 196, 63, 0.25) 0px 13px 47px -5px, rgba(180, 71, 71, 0.3) 0px 8px 16px -8px;
}

.link-to-page-product {
    text-decoration: none;
    color: inherit;
}

.link-to-page-product:hover {
    text-decoration: none;
}