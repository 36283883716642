.product-page {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.product-gallery {
    width: 60%;
}

.product-swiper {
    border: 1px solid #ddd;
}

.product-thumbs {
    margin-top: 10px;
}

.product-details {
    width: 40%;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #373665;
}

.product-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    word-wrap: break-word;
}

.product-price {
    font-size: 18px;
    color: #373665;
    margin-bottom: 20px;
}

.product-buy-button {
    background-color: #373665;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.product-image {
    max-width: 70vh; /* Ajuste conforme necessário */
    max-height: 70vh; /* Ajuste conforme necessário */
    object-fit: cover; /* ou 'cover' dependendo do efeito desejado */
}

.product-thumb-image {
    width: 100px; /* Ajuste conforme necessário */
    height: 100px; /* Ajuste conforme necessário */
    object-fit: cover; /* ou 'contain' dependendo do efeito desejado */
}