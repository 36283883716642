.promotions-section {
  width: 100vw;
  height: 35vh;
  overflow: hidden;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


.promotions-section .mySwiper {
  height: 100%;
  max-width: 100%;
}


.promotions-section .swiper-button-prev,
.promotions-section .swiper-button-next {
  width: 30px;
  height: 30px;
  background-color: transparent;
  color: #000000;
  opacity: 0.1;
  font-size: 16px;
}


.promotions-section .swiper-button-prev:hover,
.promotions-section .swiper-button-next:hover {
  opacity: 0.5;
}

.promotions-section .mySwiper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}