.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
}

.logo {
    width: 304px;
    height: 75px;
}

.nav-bar {
    display: flex;
    align-items: center;
}

.nav-list {
    list-style-type: none;
    display: flex;
    align-items: center;
}

.nav-list>li>a {
    margin-right: 60px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    color: inherit;
}

.arrow-down-icon {
    margin-left: 5px;
    font-size: 18px;
}

.dropdown-container {
    position: relative;
}

.dropdown-menu {
    list-style-type: none;
    background-color: #f9f9f9;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0;
    margin: 0;
    display: none;
    z-index: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 200px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
    font-size: 16px;
    color: #24292e;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-container:hover .dropdown-menu {
    display: block;
    border: 1px solid #ccc;
}

.dropdown-container:hover{
    cursor: pointer;
}

.dropdown-menu li:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.dropdown-menu li a {
    text-decoration: none;
    white-space: nowrap;
    color: inherit;
}

.nav-list>li {
    transition: color 0.3s ease;
}

.nav-list>li:hover {
    color: #007BDA;
}

.dropdown-menu li {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-menu li:hover {
    background-color: #f1f1f1;
    color: #007BDA;
}

li.active {
    color: #007BDA;
}

.dropdown-container > div.active {
    color: #007BDA;
}