.card-product-type {
    width: 40%;
    height: 200px;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    padding: 1.8rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
    margin: 12px 20px;
}

.card-product-type-details {
    color: black;
    height: 100%;
    gap: .5em;
    display: grid;
    place-content: center;
}

.product-type-card-button {
    transform: translate(-50%, 125%);
    width: 60%;
    border-radius: 1rem;
    border: none;
    background-color: #373665;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
}

.product-type-text-title {
    font-size: 1.5em;
    font-weight: bold;
}

.card-product-type:hover {
    text-decoration: underline #373665;
    border-color: #373665;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}

.card-product-type:hover .product-type-card-button {
    transform: translate(-50%, 50%);
    opacity: 1;
}

.card-product-type-details img{
    max-width: 400px;
    max-height: 150px;
    overflow: hidden;
}