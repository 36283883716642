.benefits-section {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 70px;
    margin: 25px 0;
    overflow-x: hidden;
}

.benefits-section ul {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding: 0;
}

.benefits-section li {
    display: flex;
    align-items: center;
}

.benefit-icon svg {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    color: #373665;
}

.separator {
    height: 1px;
    width: 60px;
    background-color: #373665;
    margin: 0 10px;
}

.benefits-section ul li .benefit-text {
    font-size: 26px;
    font-weight: bold !important;
    color: #373665;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    padding-top: 5px;
}

.benefits-section ul li .benefit-text small {
    display: block;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 2px;
    font-weight: normal !important;
}