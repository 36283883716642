@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

html, body, h1, h2, h3, h4, h5, h6, p, ol, ul, li {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}
